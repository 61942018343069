"use client";
import React, { useEffect, useState } from "react";
import Image from "next/image";
import { trackCartEvent } from "./tracker";
import { cart } from "@/cart";
import { Plus, Minus, Trash } from "@phosphor-icons/react/dist/ssr";
import classNames from "classnames";
import { Cart as CartType } from "@cappelendamm/e-commerce-services";

type ShowCartProps = {
  className?: string;
};

export const Cart = ({ className }: ShowCartProps) => {
  const [cartData, setCartData] = useState<CartType>();
  useEffect(() => {
    const getCartData = async () => {
      const cartFunction = await cart();
      const cartData = await cartFunction.get();
      return setCartData(cartData);
    };
    getCartData();
  }, []);
  if (!cartData) return null;
  return (
    <div className={classNames(className)}>
      {cartData.items.map((item: any) => (
        <div className="relative flex items-center justify-center gap-2 p-2">
          <Image
            className="rounded"
            src={item.images[0].url || ""}
            height={200}
            width={84}
            alt="Bok bilde"
          />
          <div className="w-full">
            <div>{item.name}</div>
            <div>
              {item.price.net} {item.price.currency}
            </div>
          </div>
          <div className="flex flex-col items-center justify-center">
            <button
              className="rounded-full bg-info p-2"
              onClick={async () => {
                const { increaseQuantity } = await cart();
                const data = await increaseQuantity({
                  sku: item.variant.sku,
                });
                setCartData(data);
                trackCartEvent(data, "add_to_cart", {
                  item_list_name: "product_page_scroll_box",
                });
              }}
            >
              <Plus size={24} />
            </button>
            <div>{item.quantity}</div>
            <button
              className="rounded-full bg-info p-2"
              onClick={async () => {
                const { decreaseQuantity } = await cart();
                const data = await decreaseQuantity({
                  sku: item.variant.sku,
                });
                setCartData(data);
                trackCartEvent(data, "remove_from_cart", {
                  item_list_name: "product_page_scroll_box",
                });
              }}
            >
              <Minus size={24} />
            </button>
            <button
              className="mt-4 rounded-full bg-info p-2"
              onClick={async () => {
                const { removeItem } = await cart();
                const data = await removeItem({
                  sku: item.variant.sku,
                });
                setCartData(data);
                trackCartEvent(data, "remove_from_cart", {
                  item_list_name: "product_page_scroll_box",
                });
              }}
            >
              <Trash size={24} />
            </button>
          </div>
        </div>
      ))}
      {`Total : ${cartData.total.net} ${cartData.total.currency}`}
    </div>
  );
};
