"use client";
import React, { useState } from "react";
import classNames from "classnames";
import Link from "next/link";
import { Cart } from "@/cart";
import { PiArrowRight, PiBasket, PiX } from "react-icons/pi";

export default function HeaderCart({}) {
  const [popOver, setPopOver] = useState(false);

  return (
    <div className="absolute">
      <button
        className="text-sm"
        onClick={() => {
          setPopOver(true);
        }}
      >
        <PiBasket className="inline" size={24} />
      </button>
      {popOver && (
        <div className="relative right-[45%] z-[99]">
          <div className="relative m-auto rounded border bg-white pb-4">
            <h2 className="border-b p-8 pr-24 text-2xl font-semibold">
              Din cart
            </h2>
            <button
              className="absolute right-4 top-4 rounded-full bg-[#F7F7F8] p-4"
              onClick={() => setPopOver(false)}
              aria-label="Lukk Modal"
              type="button"
            >
              <PiX className="inline" size={24} />
            </button>
            <Cart className="rounded bg-white p-4" />
            <div className="m-4 flex flex-col gap-4">
              <Link
                href={"/checkout"}
                className={classNames(
                  "cvr-button w-fit bg-systemStatus-info-500 text-white",
                )}
              >
                Gå til checkout
                <PiArrowRight />
              </Link>
              <button
                className={classNames(
                  "cvr-button w-fit border border-systemStatus-info-500 bg-white text-systemStatus-info-500",
                )}
                onClick={() => setPopOver(false)}
              >
                Lukk
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
